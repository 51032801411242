import React, { useEffect, useState } from 'react';
import Session from '../../../models/Session/Session';
import Modal from '../../../components/Modal/Modal'
import styles from './OrderDetails.module.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

const SelectableArea = styled.div`
    & * {
        user-select: text !important;
    }
`;

const OrderDetails: React.FC<{ orderDetails: Session }> = ({
  orderDetails,
}) => {
  const { t } = useTranslation();

  // 2D Array representing whether or not each image is reused
  const [reusedImages, setReusedImages] = useState<boolean[][]>(
    orderDetails.submissions.map(() => new Array<boolean>(4).fill(false))
  );

  const [modalImage, setModalImage] = useState<string>('');

    const openModal = (name: string) => {
        setModalImage(name)
    }

  // Detect reused images
  useEffect(() => {
    const imageBase64BySubmission = orderDetails.submissions.map((submission) =>
      submission.images.map((image) => image.base64ImageData)
    );
    // Transpose to organize by image
    const imageBase64ByImage = imageBase64BySubmission[0].map((_, colIndex) =>
      imageBase64BySubmission.map((row) => row[colIndex])
    );
    imageBase64ByImage.forEach((imageTypeCol, imageIndex) => {
      // Compare all the images for the image type
      imageTypeCol.forEach((base64Image, submissionIndex) => {
        if (submissionIndex === 0) return;
        const prevBase64Image = imageTypeCol[submissionIndex - 1];
        const reusedImagesTemp = reusedImages.map((e) => e);
        reusedImagesTemp[submissionIndex][imageIndex] =
          base64Image === prevBase64Image;
        setReusedImages(reusedImagesTemp);
      });
    });
  }, [orderDetails.submissions]);

  return (
    <SelectableArea>
      <div className={styles.Header}>
        <div className={styles.Order}>
          <p>{t('licenceId')}</p>
          <p>{orderDetails.licenceId}</p>
        </div>
        <div className={styles.Order}>
          <p>{t('licenseName')}</p>
          <p>{orderDetails.licenseName}</p>
        </div>
        <div className={styles.Order}></div>
        <div className={styles.Info}>
          <p>{t('diagnosticResult')}</p>
          <p>{orderDetails.diagnosticResult}</p>
        </div>
      </div>
      {orderDetails.submissions.map((submission, submissionIndex) => (
        <div className={styles.Body} key={submissionIndex}>
          <div className={styles.Side}>
            <h3>{t('analysisOutput')}</h3>
            <p>{submission.analysisResult}</p>
          </div>
          <div className={styles.Main}>
            <div className={styles.Timestamp}>{submission.submittedOn}</div>
            <div className={styles.Images}>
              {submission.images.map((image, imageIndex) => (
                <React.Fragment key={image.name}>
                <div className={styles.Image} onClick={() => openModal(image.name)}>
                  <div className={image.valid ? '' : styles.Red}>
                    <p>
                      {reusedImages[submissionIndex][imageIndex]
                        ? '[REUSED]'
                        : '\xa0'}
                    </p>
                    <p>{image.name}</p>
                  </div>
                  <img
                    src={`data:image/jpeg;base64,${image.base64ImageData}`}
                    className={image.valid ? '' : styles.Border}
                    alt=""
                  />
                  {image.orientation != null && image.fixation != null && (
                    <div className={image.valid ? '' : styles.Red}>
                      <p>
                        {image.orientation}{' '}
                        {image.fixation.replace('Centered', '')}
                      </p>
                      <p></p>
                      <p>
                        {`Quality Index: ${image.imageQualityIndex.toFixed(2)}`}
                      </p>
                    </div>
                  )}
                </div>
                <Modal isVisible={modalImage === image.name} onClose={() => setModalImage('')}>
                  <img src={`data:image/jpeg;base64,${image.base64ImageData}`} alt="" />
                  <p className={styles.ImageName}>{image.name}</p>
                </Modal>
              </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ))}
    </SelectableArea>
  );
};

export default OrderDetails;
