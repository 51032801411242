const appConfig = {
  REACT_APP_STAGE: '{{REACT_APP_STAGE}}',
  REACT_APP_API_URL: '{{REACT_APP_API_URL}}',
  REACT_APP_COGNITO_CLIENT_ID: '{{REACT_APP_COGNITO_CLIENT_ID}}',
  REACT_APP_TOKEN_ENDPOINT: '{{REACT_APP_TOKEN_ENDPOINT}}',
  REACT_APP_COGNITO_HOSTED_UI: '{{REACT_APP_COGNITO_HOSTED_UI}}',
};

export type AppConfig = typeof appConfig;
export default appConfig;
