import React, { useState } from 'react';
import styles from './Login.module.css';
import logo from '../../assets/images/logo.png';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Spinner, Button } from '@ddx/component-library';
import appConfig from '../../shared/appConfig';

const Login: React.FC<{
  loading: boolean;
  isAuthenticated: boolean;
  error: any;
  onLogin: (username: string) => VoidFunction;
}> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const formSubmitHandler = () => {
    const cognitoHostedUi = appConfig.REACT_APP_COGNITO_HOSTED_UI as string;

    window.location.replace(cognitoHostedUi + '&state=' + location.pathname);
  };

  const args = {
    type: 'button',
  };

  return (
    <div className={styles.Login}>
      {props.isAuthenticated && <Redirect to="/" />}
      <div className={styles.Logo}>
        <img src={logo} />
      </div>
      <form className={styles.Form}>
        <Button {...args} variant="primary" onClick={formSubmitHandler}>
          {t('login')}
        </Button>
        {props.loading && (
          <div className={styles.Loading}>
            <Spinner />
          </div>
        )}
        <div className={styles.Error}>
          {props.error && props.error.data && !props.loading
            ? props.error.data.message
            : null}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.user != null,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogin: (code: string) => dispatch(actions.login(code)),
    onReset: () => dispatch(actions.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
