import React from 'react'
import { useLocation } from 'react-router'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/NavBar/NavBar'
import styles from './Layout.module.css'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { useEffect } from 'react'
import * as actions from '../../store/actions/'
import { History } from 'history'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom';

interface ChildComponentProps extends RouteComponentProps<any> {
    isAuthenticated: boolean,
    onLogin: (code: string | string[]) => {},
    children: any
  }

const Layout: React.FC<ChildComponentProps> = props => {

    const location = useLocation()
    useEffect(() => {
        const params = queryString.parse(location.search)
        if (params.code) {
            props.onLogin(params.code)
        }
        
        if (params.state) {
            const page = params.state as string
            console.log(page);
            props.history.push(page)
        }
    }, [])

    return (
        <>
            { props.isAuthenticated ? <Navbar/> : null }
            <div className={styles.Main}>
                {props.children}
            </div>
            { props.isAuthenticated ? <Footer/> : null }
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
      isAuthenticated: state.auth.user !== null,
      user: state.auth.user
    }
  }

const mapDispatchToProps = (dispatch: any) => {
return {
    onCheckAuthState: () => dispatch(actions.checkAuthState()),
    onLogin: (code: string | string[]) => dispatch(actions.login(code)),
}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout))