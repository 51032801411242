import React, { useEffect, useState } from 'react';
import OrderDetails from './SingleExam/OrderDetails';
import styles from '../Containers.module.css';
import axios from '../../axios-exam-api';
import Session from '../../models/Session/Session';
import style from './Exam.module.css';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { Spinner } from '@ddx/component-library';
import { NotificationBox } from '@ddx/component-library';

const Exam: React.FC<{ match: any }> = (props) => {
  const [order, setOrder] = useState<Session>();
  const [error, setError] = useState<{ data: { Message: string } }>();
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      let endpoint = '';
      const pathname = window.location.pathname;
      if (pathname.startsWith('/exams/')) {
        endpoint = `/api/Session/GetSession/${props.match.params.sessionId}`;
      } else if (pathname.startsWith('/examsBySubmissionId/')) {
        endpoint = `/api/Session/GetSessionBySubmissionId/${props.match.params.submissionId}`;
      }

      axios
        .get(endpoint, {
          headers: {
            Accept: '*/*',
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          setOrder(response.data);
        })
        .catch((error) => {
          setError(error.response);
        });
    }
  }, [props.match.params.sessionId, props.match.params.submissionId]);

  return (
    <div className={styles.Container}>
      {order && <OrderDetails orderDetails={order} />}
      {!order && !error ? (
        <div className={style.Loading}>
          <Spinner />
        </div>
      ) : null}
      {error && (
        <div className={style.NotificationWrapper}>
          <NotificationBox iconType="exclamation">
            <p className={style.Notification}>{t('somethingWrong')}</p>
          </NotificationBox>
        </div>
      )}
    </div>
  );
};

export default Exam;
