import { useTranslation } from 'react-i18next'
import '../../translations/i18n'
import * as actions from '../../store/actions/'
import { connect } from 'react-redux'
import { NavBar as NavigationBar, Button } from '@ddx/component-library'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const Navbar = () => {
  const { t } = useTranslation()
  let history = useHistory();

  const MenuItem = styled.button`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
    padding: 8px 4px;
    display: block;
    margin: 0;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.25px;
    color: #253746;
    cursor: pointer;
    :hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }
`;

  const args = {
    type: 'button',
    style: {
      margin: '15px 0 0 0'
    }
  };

  const DefaultApplicationMenu = () => (
    <>
      <MenuItem>Profile</MenuItem>
      <MenuItem>My account</MenuItem>
      <Button {...args} sizeVariant="small" onClick={() => history.push('/logout')}>Logout</Button>
    </>
  )

  return (
    <NavigationBar title={t('siteTitle')} applicationMenu={DefaultApplicationMenu} />
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCheckAuthState: () => dispatch(actions.checkAuthState())
  }
}

export default connect(mapDispatchToProps)(Navbar)