import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/';

const Logout:React.FC<{onLogout: () => {}}> = (props) => {
  useEffect(() => {
    props.onLogout();
  }, []);

  return <Redirect to="/" />
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(actions.authLogout())
  }
}

export default connect(null, mapDispatchToProps)(Logout);
