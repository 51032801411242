import React from 'react'
import Backdrop from '../Backdrop/Backdrop'
import styles from './Modal.module.css'

const Modal: React.FC<{isVisible: boolean; onClose: () => void}> = props => {
    return (
        <div className={props.isVisible ? '' : styles.Hide}>
            <Backdrop onClick={props.onClose}/>
            <div className={styles.Modal}>
                {props.children}
            </div>
        </div>
    )
}

export default Modal